<template>
  <div id="thebody" style="clear: both">
    <table id="thetable">
      <tr>
        <ConfigurationResources :isRestricted="account.isRestricted" :resourceList="resourceList"/>
        <ConfigurationIncidents :isRestricted="account.isRestricted" :incidentList="incidentList"/>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: mapState({
    resourceList: state => state.resourceList,
    incidentList: state => state.incidentList,
    account: state => state.account
  }),
  components: { 
    ConfigurationResources: () => import("./ConfigurationResources"), 
    ConfigurationIncidents: () => import("./ConfigurationIncidents") }
};
</script>
